<template>
  <div class="offer-container">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="logo-container">
          <img :width="imgWidth" :height="imgHeight" alt="BizCover-logo" :src="logoFile" />
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="buttons-container">
          <a v-if="islinkActive" :href="linkVisit" :class="{ 'btn-utilities': type }" @click="addTrackEvent('get Quote')" class="btn btn-sm btn-secondary btn-visit m-b-10" target="_blank" rel="noopener">{{
            labelButtonVisit
          }}</a>
          <a class="btn btn-sm btn-secondary" target="_blank" href="https://calendly.com/matthew-519" @click="addTrackEvent('request callback')">
            {{ labelButtonCallback }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferSavingsDetails',
  props: {
    logoAssetFolderFile: {
      required: true,
      type: String
    },
    linkVisit: {
      required: true,
      type: String
    },
    islinkActive: {
      required: true,
      type: Boolean
    },
    labelButtonVisit: {
      required: true,
      type: String
    },
    labelButtonCallback: {
      required: true,
      type: String
    },
    callBackLink: {
      required: true,
      type: String
    },
    type: {
      required: false,
      type: String
    },
    imgWidth: {
      required: true,
      type: String
    },
    imgHeight: {
      required: true,
      type: String
    }
  },
  computed: {
    logoFile() {
      try {
        return require('@tenant/assets/images/insurance/' + this.logoAssetFolderFile)
      } catch (e) {
        return null
      }
    }
  },
  methods: {
    addTrackEvent(eventType) {
      this.$ma.trackEvent({
        eventType: eventType,
        eventProperties: {
          category: 'Insurance',
          provider: 'BizCare'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/swoop/variables';

.offer-container {
  border-radius: 12px;
  background-color: $color-white;
  box-shadow: 0 2px 12px 0 rgba(51, 51, 79, 0.06);
  margin-bottom: 3rem;
  margin-top: 1rem;

  .logo-container {
    min-height: 204px;
    border: 1px solid var(--color-primary-200);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px 0 32px 66px;

    @media only screen and (max-width: $breakpoint-md-max) {
      margin: 1rem;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media only screen and (max-width: $breakpoint-md-max) {
      padding: 1rem;
    }

    .btn {
      width: 210px;
    }
    .btn-visit {
      background: var(--color-primary-500);
      color: $color-white;
      width: 210px;
    }
    .btn-utilities {
      background: var(--color-error-600);
    }
  }
}
</style>
