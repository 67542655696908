<template>
  <div class="bizcover-insurance-page">
    <!-- HEADER -->
    <HeaderSavingsDetails title="BizCover" description="Insurance" logoAssetFolderFile="bizcover.png" />

    <!-- MAIN -->
    <main class="m-b-20">
      <!-- SUBHEADER INFO -->

      <div class="ui container">
        <SubHeaderSavingsDetails />
        <!-- OFFER INFO -->

        <OfferInsuranceDetails
          :islinkActive="true"
          linkVisit="https://insure.bizcover.com.au/OccupationSelection?utm_source=SWOOP1&utm_medium=Form&utm_campaign=SWOOP1%27,LogoLocation=%27images/Affiliates/Swoop"
          labelButtonVisit="get a Quote"
          labelButtonCallback="Request Callback"
          callBackLink="gttps://calendly.com/matthew-519"
          logoAssetFolderFile="bizcover.png"
          imgWidth="auto"
          imgHeight="auto"
        />
      </div>
    </main>

    <!-- FOOTER INFO -->
    <div class="card">
      <div class="ui container row">
        <div class="col-xs">
          <h3 class="m-t-25 title-footer">Stay one step ahead with business insurance from BizCover.</h3>
          <div class="container-footer">
            <p>
              <strong>BizCover is a specialist small business insurer offering bespoke cover to hundreds of Aussie small
                businesses. Tailored business insurance can provide financial cover for your business, including the
                premises and contents, against loss, damage or theft as well as cover against any resulting financial loss
                from an insured interruption to the business.</strong>
              <br/><br/>
                To get an online guided quote in minutes based on your individual business needs please click ‘Get a Quote’
                above.
              <br/><br/>
              <strong>Or, if you would prefer to speak to an insurance expert who will help you find the right cover for
                your needs, call <a class="link-phone" href="`tel:0800 840 2768`">1300 920 875.</a> If you’re not quite ready to get a quote
                and would like to find out more about business insurance, click here. </strong>
              <br/><br/>
              <em>*As with any insurance, cover is subject to the terms, conditions and exclusions contained in the policy wording.
                The information contained on this website is general only and should not be relied upon as advice.</em>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSavingsDetails from './components/HeaderInsuranceDetails'
import SubHeaderSavingsDetails from '@/features/savings/components/SubHeaderSavingsDetails'
import OfferInsuranceDetails from './components/OfferInsuranceDetails'

export default {
  name: 'Insurance',
  components: {
    HeaderSavingsDetails,
    SubHeaderSavingsDetails,
    OfferInsuranceDetails
  },
  mounted() {
    this.$ma.trackEvent({
      eventType: 'PV insurance',
      eventProperties: {
        category: 'Insurance',
        provider: 'Tat Capital'
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/swoop/variables';
.bizcover-insurance-page {
  width: 100%;
}
.offer {
  min-height: 349px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.highlighted {
  color: $color-secondary;
}
</style>
