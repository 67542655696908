<template>
  <div class="product-details-nav m-b-25 p-t-20">
    <BackBtn />
    <NeedHelp />
  </div>
</template>

<script>
import NeedHelp from '@/components/NeedHelp'
import BackBtn from '@/components/BackBtn'
export default {
  name: 'SubHeaderSavingsDetails',
  components: {
    NeedHelp,
    BackBtn
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';
.product-details-nav {
  a {
    &:last-child {
      color: var(--color-secondary-500);
    }
  }
  @media only screen and (max-width: $breakpoint-sm) {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  }
  button,
  .btn {
    @media only screen and (max-width: $breakpoint-sm) {
      margin-bottom: 1rem;
    }
  }
}
</style>
